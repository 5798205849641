import React from "react"
import classnames from "classnames"

import Link from "../Link"

import styles from "./styles.module.css"

const Footer = () => {
  const date = new Date()

  return (
    <footer
      role="contentinfo"
      aria-label="Footer"
      className="flex flex-col py-8 text-center mt-16"
    >
      <div className={classnames(styles.text, "font-mono text-sm line-lead")}>
        <small>© {date.getFullYear()} — Albert Fernández</small>
      </div>
      <div className="block mt-2">
        <Link className="text-xs" href="https://github.com/albertfdp/www">
          Source Code
        </Link>
      </div>
    </footer>
  )
}

export default Footer
