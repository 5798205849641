import { useEffect } from "react"

function useScrollToHash(location) {
  useEffect(() => {
    let timeout

    if (location && location.hash) {
      const id = location.hash.substring(1)
      timeout = setTimeout(() => {
        const elm = document.getElementById(id)

        if (elm) {
          elm.scrollIntoView()
          elm.focus()
        }
      }, 0)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [location])
}

export default useScrollToHash
