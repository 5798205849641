import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./styles.css"

const Section = ({ className, children, ...props }) => {
  return (
    <section className={classnames(className, "section")} {...props}>
      {children}
    </section>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

const Content = ({ className, children }) => (
  <div
    className={classnames(
      "container",
      "flex",
      "flex-col",
      "items-center",
      "mx-auto",
      className
    )}
  >
    {children}
  </div>
)

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

const Title = ({ className, children, ...props }) => (
  <h2
    className={classnames(
      className,
      "section-title",
      "flex",
      "font-bold",
      "font-mono",
      "items-center",
      "text-lg",
      "md:text-3xl",
      "mb-12",
      "mx-0",
      "mt-12",
      "md:mt-4",
      "relative",
      "section-title",
      "w-full",
      "whitespace-no-wrap"
    )}
    {...props}
  >
    {children}
  </h2>
)

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Section.Content = Content
Section.Title = Title

export default Section
