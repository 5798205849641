import React from "react"
import PropTypes from "prop-types"

import { MDXProvider } from "@mdx-js/react"

import OutboundLink from "../Link"
import { Link } from "gatsby"

import Header from "../Header"
import Footer from "../Footer"

import useScrollToHash from "../../hooks/useScrolltoHash"

import "./styles.css"

const shortcodes = {
  Link,
  OutboundLink,
}

const Layout = ({ children, location }) => {
  useScrollToHash(location)

  return (
    <MDXProvider components={shortcodes}>
      <Header />
      <main id="main" className="main px-8 md:px-8">
        {children}
      </main>
      <Footer />
    </MDXProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
}

export default Layout
