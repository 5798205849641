import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { Link } from "gatsby"

import ThemeSwitcher from "../ThemeSwitcher"

import styles from "./styles.module.css"

const LinkItem = ({ to, children }) => {
  return (
    <li className={classnames(styles.link, "mx-4 relative")}>
      <Link className={styles.link} to={to}>
        {children}
      </Link>
    </li>
  )
}

LinkItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
}

const Header = () => (
  <header role="banner" className="flex justify-between relative py-6 px-4">
    <a href="#main" className="sr-only focus:not-sr-only">
      Skip to main content
    </a>
    <div className="flex flex-grow justify-end">
      <nav
        aria-label="Main navigation"
        role="navigation"
        className="flex-grow flex justify-end mr-8"
      >
        <ul className="hidden md:flex list-none">
          <LinkItem to="/">Home</LinkItem>
          <LinkItem to="/#about">About</LinkItem>
          <LinkItem to="/#jobs">Experience</LinkItem>
          <LinkItem to="/#works">Works</LinkItem>
        </ul>
      </nav>
      <ThemeSwitcher />
    </div>
  </header>
)

export default Header
