import React from "react"
import useDarkMode from "use-dark-mode"

import MoonIcon from "feather-icons/dist/icons/moon.svg"
import SunIcon from "feather-icons/dist/icons/sun.svg"

const ThemeSwitcher = () => {
  const { value, toggle } = useDarkMode(false)

  return (
    <button
      onClick={toggle}
      aria-label={value ? "Activate dark mode" : "Disable dark mode"}
    >
      {value ? <SunIcon /> : <MoonIcon />}
    </button>
  )
}

export default ThemeSwitcher
