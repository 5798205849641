import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./styles.css"

const Link = ({ className, children, ...props }) => (
  <a
    className={classnames(
      className,
      "link",
      "inline-block",
      "no-underline",
      "relative",
      "transition",
      "duration-300"
    )}
    {...props}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
)

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Link
